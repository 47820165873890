/* eslint-disable */
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from '../layout/layout';
import { Role } from '../modules/roles';
import { Dashboard } from '../modules/dashboard';
import { Login } from '../modules/Login';
import { Module } from '../modules/module';
import { SubModule } from '../modules/sub-module';
import AddRole from '../pages/role';
import AddFormModal from '../modules/module/FormModal';
import FormModal from '../modules/sub-module/FormModal';
import { NotFound } from '../modules/NotFound';
import ChangePassword from '../modules/Login/ChangePassword'
import AuthRoutes from './AuthRoutes';

const Router: React.FC = () => (
  <Routes>
    <Route path="/" element={<Login />} />
    <Route element={<AuthRoutes />}>
      <Route path="/dashboard" element={<Layout><Dashboard /></Layout>} />
      <Route path="/roles" element={<Layout><Role /></Layout>} />
      <Route path="/module" element={<Layout><Module /></Layout>} />
      <Route path="/sub-module" element={<Layout><SubModule /></Layout>} />
      <Route path="/roles/add" element={<Layout><AddRole /></Layout>} />
      <Route path="/modules/add" element={<Layout><AddFormModal /></Layout>} />
      <Route path="/sub-modules/add" element={<Layout><FormModal /></Layout>} />
      <Route path="/change-password" element={<Layout><ChangePassword /></Layout>} />
    </Route>
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default Router;
