/* eslint-disable */
import React, { useState } from 'react';
import {
  AppBar,
  Grid,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
} from '@mui/material';
import { KeyboardArrowDown, Logout } from '@mui/icons-material';
import { Image } from '../components/Image';
import { Icon } from '../components/Icon';
import { Avatar } from '../components/Avatar';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const baseUrl = window.location.origin;
const imageLogo = `${baseUrl}/image 5.png`;

const Header = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const handleMenuOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/';
  };

  const hanldeChangePassowrd = () => {
    navigate("/change-password");
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: '#fff',
        padding: '10px',
        boxShadow: 'none',
        borderBottom: '1px solid #EEEEEE',
      }}
    >
      <Grid item xs={6} md={6}>
        <Image src={imageLogo} alt="Successive logo" />
      </Grid>

      <Grid display="flex" item xs={6} md={6} justifyContent="end">
        <Avatar alt="Avatar.png" src={`${baseUrl}/Ellipse 2.png`} />
        <Grid
          pl={1}
          className="pr-3"
          onClick={handleMenuOpen}
          style={{ cursor: 'pointer' }}
        >
          <Typography variant="body1">ADMIN</Typography>
          <Typography style={{ color: '#2559C3' }}>Admin</Typography>
        </Grid>
        <Box
          onClick={handleMenuOpen}
          sx={{ display: 'flex', alignItems: 'center', color: '#2559C3', cursor: 'pointer' }}
        >
          <Icon>
            <KeyboardArrowDown sx={{ fontSize: '25px' }} />
          </Icon>
        </Box>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
          <MenuItem onClick={hanldeChangePassowrd}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Change Password
          </MenuItem>
        </Menu>
      </Grid>
    </AppBar>
  );
};

export default Header;
