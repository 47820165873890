/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const decodeBase64Url = (base64Url: string): string => {
    try {
        if (!base64Url) return '';
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        return atob(base64);
        } catch (error) {
        console.error('Invalid base64 string:', error);
        return '';
    }
  };

const isTokenValid = (token: string | null): boolean => {
  if (!token) return false;

  try {
    const parts = token.split('.');
    if (parts.length !== 3) return false;
    const [, payload] = parts;
    if (!payload) return false;

    const decodedPayload = JSON.parse(decodeBase64Url(payload) || '{}');
    const currentTime = Date.now() / 1000;

    if (!decodedPayload.exp || typeof decodedPayload.exp !== 'number' || decodedPayload.exp < currentTime) {
      localStorage.removeItem('token');
      return false;
    }

    return true;
  } catch (error) {
    console.error('Error decoding token:', error);
    localStorage.removeItem('token');
    return false;
  }
};

const AuthRoutes: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(isTokenValid(localStorage.getItem('token')));

  useEffect(() => {
    const handleStorageChange = () => {
      setIsAuthenticated(isTokenValid(localStorage.getItem('token')));
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return isAuthenticated ? <Outlet /> : <Navigate to="/" replace />;
};

export default AuthRoutes;
