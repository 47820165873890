/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, TextField, Button, Snackbar, Alert } from "@mui/material";
import axios from "axios";

const ChangePassword: React.FC = () => { 
  const [email, setEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [alert, setAlert] = useState({ open: false, message: "", severity: "info" });

  useEffect(() => {
    const storedEmail = localStorage.getItem("user");
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  const handleChangePassword = async () => {
    if (!oldPassword || !newPassword || !confirmPassword) {
      setAlert({ open: true, message: "All fields are required.", severity: "warning" });
      return;
    }
    if (newPassword !== confirmPassword) {
      setAlert({ open: true, message: "New Password and Confirm Password should match.", severity: "error" });
      return;
    }

    try {
      const { data } = await axios.put(`${process.env.REACT_APP_API_URL}/user/update`, {
        email,
        oldPassword,
        newPassword,
      });
      
      if (data.success) {
        setAlert({ open: true, message: "Password updated successfully!", severity: "success" });
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
      } else {
        setAlert({ open: true, message: data.message || "Failed to update password.", severity: "error" });
      }
    } catch (error) {
      setAlert({ open: true, message: "Error updating password. Please try again.", severity: "error" });
    }
  };

  return (
    <Card sx={{ maxWidth: 400, margin: "auto", mt: 5, p: 3 }}>
      <CardHeader title="Change Password" />
      <CardContent>
        <TextField
          label="Old Password"
          type="password"
          fullWidth
          margin="normal"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
        />
        <TextField
          label="New Password"
          type="password"
          fullWidth
          margin="normal"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <TextField
          label="Confirm Password"
          type="password"
          fullWidth
          margin="normal"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <Button variant="contained" color="primary" fullWidth sx={{ mt: 2 }} onClick={handleChangePassword}>
          Update Password
        </Button>
      </CardContent>

      <Snackbar open={alert.open} autoHideDuration={4000} onClose={() => setAlert({ ...alert, open: false })}>
        <Alert onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity as any}>
          {alert.message}
        </Alert>
      </Snackbar>
    </Card>
  );
};

export default ChangePassword;